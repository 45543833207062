import { useState, useEffect } from "preact/hooks";
import { useSignal } from "@preact/signals";
import { ThemeToggle } from "./ThemeToggle.tsx";
import { getSiteConfig } from "../config/sites.ts";
import { Icons } from "../config/icons.tsx";

declare global {
  interface Window {
    SITE_NAME: string;
  }
}

export function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [config, setConfig] = useState<ReturnType<typeof getSiteConfig> | null>(null);
  
  useEffect(() => {
    // Use the globally available site name that was set in _app.tsx
    if (typeof window !== 'undefined' && window.SITE_NAME) {
      setConfig(getSiteConfig(window.SITE_NAME));
    }
  }, []);

  const showDebug = typeof window !== 'undefined' 
    ? new URLSearchParams(window.location.search).get('debug') === '1'
    : false;

  // Show loading state or fallback while config is not available
  if (!config) {
    return <nav class="bg-white dark:bg-gray-800 shadow"><div class="h-16"></div></nav>;
  }

  return (
    <>
      {showDebug && (
        <div style="position: fixed; bottom: 10px; left: 10px; background: rgba(0,0,0,0.8); color: white; padding: 10px; z-index: 9999; font-family: monospace; font-size: 12px;">
          <pre>
            Config: {JSON.stringify(config, null, 2)}
            Company name: {config?.company?.name}
          </pre>
        </div>
      )}

      <nav class="bg-white dark:bg-gray-800 shadow">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="flex justify-between h-16">
            <div class="flex">
              <div class="flex-shrink-0 flex items-center">
                <a href="/" class="flex items-center">
                  {Icons.logo}
                  <span class="text-xl font-bold text-[var(--color-primary)]">
                    {config?.company?.name}
                  </span>
                </a>
              </div>
              <div class="hidden sm:ml-6 sm:flex sm:space-x-8">
                <a href="/" class="border-transparent text-gray-500 dark:text-gray-300 hover:text-[var(--color-primary)] hover:border-[var(--color-primary)] inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                  Home
                </a>
                <a href="/about" class="border-transparent text-gray-500 dark:text-gray-300 hover:text-[var(--color-primary)] hover:border-[var(--color-primary)] inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                  About
                </a>
                <a href="/portfolio" class="border-transparent text-gray-500 dark:text-gray-300 hover:text-[var(--color-primary)] hover:border-[var(--color-primary)] inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium">
                  Portfolio
                </a>
              </div>
            </div>
            <div class="flex items-center">
              <ThemeToggle />
              <div class="-mr-2 flex items-center sm:hidden">
                <button
                  onClick={() => setIsOpen(!isOpen)}
                  class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[var(--color-primary)]"
                >
                  <span class="sr-only">Open main menu</span>
                  {!isOpen ? Icons.menuOpen : Icons.menuClose}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <div class={`${isOpen ? "block" : "hidden"} sm:hidden`}>
          <div class="pt-2 pb-3 space-y-1">
            <a
              href="/"
              class="text-[var(--color-primary)] block pl-3 pr-4 py-2 border-l-4 border-[var(--color-primary)] text-base font-medium"
            >
              Home
            </a>
            <a
              href="/about"
              class="border-transparent text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >
              About
            </a>
            <a
              href="/portfolio"
              class="border-transparent text-gray-500 hover:bg-gray-50 dark:hover:bg-gray-700 hover:border-gray-300 hover:text-gray-700 dark:text-gray-300 dark:hover:text-white block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
            >
              Portfolio
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}
