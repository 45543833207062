export const Icons = {
  logo: (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="mr-2"
    >
      <circle cx="20" cy="20" r="19" class="stroke-[var(--color-primary)]" stroke-width="2"/>
      <path
        d="M10 20C10 20 15 15 20 15C25 15 30 20 30 20C30 20 25 25 20 25C15 25 10 20 10 20Z"
        class="stroke-[var(--color-primary)]"
        stroke-width="2"
        fill="none"
      />
      <circle cx="20" cy="20" r="4" class="fill-[var(--color-primary)]"/>
      <circle cx="10" cy="20" r="2" class="fill-[var(--color-primary)]"/>
      <circle cx="30" cy="20" r="2" class="fill-[var(--color-primary)]"/>
    </svg>
  ),
  menuOpen: (
    <svg
      class="block h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 6h16M4 12h16M4 18h16"
      />
    </svg>
  ),
  menuClose: (
    <svg
      class="block h-6 w-6"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  )
};
